import { create } from 'zustand'

const useAltaReclamoStore = create((set) => ({
    step: 1,
    sent: false,
    validators: {},
    reclamoStatus: false,
    setValidator: (step, validatorFn) => {
        set((state) => {
            const validators = state.validators;
            validators[`validator${step}`] = validatorFn; 
            return({ ...state, validators }); 
        });
    },
    validateStep: (step, ticket) => {
        const validators = useAltaReclamoStore.getState().validators;
        if(!validators[`validator${step}`]) return false;      
        return validators[`validator${step}`](ticket);
    },
    validateAll: (ticket) => {
        const validators = useAltaReclamoStore.getState().validators;
        for(const validator of validators) {
            if(!validator(ticket)) return false;
        }
        return true;
    },
    nextStep: (ticket) => {
        const {step, validateStep} = useAltaReclamoStore.getState();
        if(!validateStep(step, ticket)) return false;
        set((state) => ({ ...state, step: state.step + 1 }));
        return true;
    },
    prevStep: (/* ticket */) => {
        // const {step, validateStep} = useAltaReclamoStore.getState();
        // if(step!=5 && !validateStep(step, ticket)) return false;
        set((state) => ({ ...state, step: state.step - 1 }));
        return true;
    },
    setStep: (newValue) => set((state) => ({ ...state, step: newValue })),
    setSent: (newValue) => set((state) => ({ ...state, sent: newValue })),
}));

export default useAltaReclamoStore;
