import React from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import BuscadorPrestaciones from '../../componentes/BuscadorPrestaciones';
import Hero from '../../componentes/hero.jsx';
import Footer from '../../componentes/footer.jsx';
import Acciones from './acciones.jsx';
import useTicketStore from '../../stores/ticket.store.js';

export default function Home() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { setPrestacion, utterance, setUtterance, reset } = useTicketStore(state => (
        {
            setPrestacion: state.setPrestacion,
            utterance: searchParams.get('search') || state.utterance,
            setUtterance: state.setUtterance,
            reset: state.reset
        }));

    const handlePrestacion = async (newValue) => {
        await reset();
        setPrestacion(newValue);
        navigate('/reclamos/alta/2', { replace: true });
    };

    return (
        <div className="flex flex-col justify-between h-[100vh]">
            <Hero className="flex-0" />

            <div className="flex-1 flex flex-col gap-5 pl-2 pr-2 sm:ml-auto sm:mr-auto w-full sm:max-w-5xl justify-center">
                <BuscadorPrestaciones
                    className="mt-2"
                    utterance={utterance}
                    onChange={handlePrestacion}
                    onChangeUtterance={setUtterance}
                />
                <Acciones />
            </div>

            <div className="flex-1 flex flex-col justify-end">
                <Footer />
            </div>
        </div>
    );
}
