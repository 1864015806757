/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import {Cuestionario, validateCuestionario} from '../../componentes/Cuestionario';
import { HelpCenter } from '@mui/icons-material';
import DropFileInput from "../../componentes/DropFileInput";
import useTicketStore from '../../stores/ticket.store';
import useAltaReclamoStore from '../../stores/altareclamo.store';

const AltaReclamosPaso3 = ({ 
  options: { 
    setMessageSnackbar, 
  }}) => {

  const {description, setDescription, answers, setAnswers, prestacion, nonce, setAttachments} = useTicketStore();
  const setValidator = useAltaReclamoStore(state => state.setValidator);

  const validator = (ticket) => {
    const errores = validateCuestionario(ticket.prestacion, ticket.answers, ticket.questionnaire);
    if (ticket.description === '') {
      errores.push('Debe completar la Nota antes de avanzar');
    }
    if (errores.length > 0) {
      setMessageSnackbar(errores.join(', '), 'error');
      return false;
    }
    return true;
  }

  useEffect(() => {
    setValidator(3, validator);    
  }, []);

  return (
    <>
      <div className='font-bold text-3xl text-[rgb(11,59,108)] mb-5'>{prestacion.name}</div>
      
      <div className='w-100 p-5 flex flex-row rounded-lg border-2 align-middle mb-5'>
        <div className='flex-0 mr-5'><HelpCenter fontSize='large'/></div>
        <div className='flex-1 text-gray-500'>
          Describí el problema. Algunas prestaciones le solicitan un cuestionario para ayudar a brindar un mejor servicio. 
          Luego haga click sobre el botón <b>Siguiente</b> al pie de este formulario
        </div>
      </div>
      
      <Cuestionario
        namespace={nonce}
        value={answers}
        prestacion={prestacion}
        onChange={setAnswers}
        options={{ setMessageSnackbar, setAttachments }}
        className={'mb-20'}
      />

      <TextField
        value={description}
        onChange={(ev) => {
          setDescription(ev.target.value);
        }}
        minRows={3}
        label="Nota"
        className='w-full'
        multiline
      />

      <div className='mt-5 text-gray-600'>Puede adjuntar fotos, videos o documentos relevantes al reclamo</div>  
      
      <DropFileInput
        namespace={nonce}
        name="attachments"
        value={prestacion?.attachments}
        options={{ setAttachments, setMessageSnackbar }}
      />

    </>);
};

AltaReclamosPaso3.propTypes = {
  options: PropTypes.object.isRequired,
};

export default AltaReclamosPaso3;
