import { create } from 'zustand'
const token = sessionStorage.getItem('token');
const user = sessionStorage.getItem('user');
const userObj = user ? JSON.parse(user) : null;
const reclamo = sessionStorage.getItem('reclamo');

const useUserStore = create((set) => ({
    user: {
        nombres: {
            error: false,
            value: userObj?.nombres?.value || '',
        },
        apellido: { 
            error: false, 
            value: userObj?.apellido?.value || '',
        },
        dni: {
            error: false,
            value: userObj?.dni?.value || '',
        },
        email: {
            error: false,
            value: userObj?.email?.value || '',
        },
        telefono: {
            error: false,
            value: userObj?.telefono?.value || '',
        },
    },
    reclamo: reclamo || '',
    token: token,

    setPersona: (newValue) => set(state => {
        sessionStorage.setItem('user', JSON.stringify({...state.user, ...newValue}));
        return { ...state, user: {...state.user, ...newValue} };
    }),
    setReclamo: (newValue) => set(state => {
        sessionStorage.setItem('reclamo', newValue);
        return { ...state, reclamo: newValue };
    }),
    setToken: (newValue) => set(state => {
        sessionStorage.setItem('token', newValue);
        return { ...state, token: newValue };
    }),
}))

export default useUserStore;
