/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import RequestElement from '../../componentes/RequestElement';
import useUserStore from '../../stores/user.store';
import useTicketStore from '../../stores/ticket.store';
import SolicitudEnviada from '../../img/v2/solicitud-enviada.jpg';
import NotificacionesPorCorreo from '../../img/v2/notificaciones-por-correo.png';
import PopUp from '../../componentes/PopUp';
const AltaReclamosPaso5 = ( ) => {
  
  const persona = useUserStore(state => state.user);
  const {ticket, direccion: dir, sent, prestacion, description} = useTicketStore();

  const direccion = dir?.modo == 'CALLE-ALTURA' ? `${dir?.calle?.nombre} ${dir?.altura}` : `${dir?.calle?.nombre} y ${dir?.calleCorta?.nombre}`;

  const tag = dir?.modo == 'CALLE-ALTURA' ? 
    `${dir?.piso != '' ? `Piso: ${dir?.piso}` : ''} ${dir?.dpto != '' ? `Departamento: ${dir?.dpto}` : ''} ${dir?.barrio != '' ? `Barrio: ${dir?.barrio}` : ''}` : '';
  
  
  return (
    <section className="completado_content">
      {!sent && <>
        <div className='font-bold text-3xl text-[rgb(11,59,108)] mb-5'>Revise los datos del reclamo antes de enviarlo</div>
        <Paper style={{ marginTop: '10px', overflow: "hidden" }}>
          <RequestElement label="prestacion" value={prestacion?.name} tag={prestacion?.metadata} />
        </Paper>
        <Paper style={{ marginTop: '10px', overflow: "hidden" }}>
          <RequestElement label="observación" value={description} style={{textOverflow: "ellipsis"}} />
        </Paper>
        <Paper style={{ marginTop: '10px', overflow: "hidden" }}>
            <RequestElement label="dirección" value={direccion} tag={tag} />
        </Paper>
        <Paper style={{ marginTop: '10px', overflow: "hidden" }}>
          <RequestElement label="nombre" value={`${persona?.nombres?.value} ${persona?.apellido?.value}`} />
          <RequestElement label="documento" value={persona?.dni?.value} />
          <RequestElement label="contacto" value={persona?.email?.value} tag={`teléfono: ${persona?.telefono?.value}`} />
        </Paper>
       
      </>}

      {sent && <div>
        <div className="flex flex-row flex-wrap items-center justify-center mb-5" >
          <img className="flex-0 w-20 mr-5" src={SolicitudEnviada} />
          <div className="text-[rgb(11,59,108)] font-['Alfabet Semi Bold'] text-4xl">SOLICITUD ENVIADA</div>
        </div>

        <div style={{boxShadow: "2px 2px 2px #666", overflow: "hidden"}} className=" flex w-full font-['Alfabet Semi Bold'] rounded-lg border-2 border-[rgb(11,59,108)]">
          
          {/* columna izq */}
          <div style={{ flex: "0 0 65%", whiteSpace: "nowrap", overflow: "hidden" }}>
            <div className='overflow-hidden border-b-2 border-[rgb(11,59,108)] w-full p-4'>
              <span style={{ fontSize: "1.1em" }}>Prestación</span><br/>
              {prestacion.name}
            </div>
            <div className='overflow-hidden border-b-2 border-[rgb(11,59,108)] w-full p-4 text-ellipsis whitespace-nowrap'>
              <span style={{ fontSize: "1.1em" }}>Problema</span><br/>
              {description}
            </div>
            <div className='w-full p-4 overflow-hidden'>
              <span style={{ fontSize: "1.1em" }}>Dirección</span><br/>
              {direccion}
            </div>
          </div>

          {/* columna der */}
          <div className='flex flex-1 max-w-[35%] flex-wrap bg-[rgb(11,59,108)] text-white content-center justify-center font-normal text-2xl overflow-hidden'>
            {ticket}
          </div>
        </div>

        
        <div className="mt-5 flex flex-wrap text-lg bg-gray-100 p-5 rounded-lg" >
          <img src={NotificacionesPorCorreo} style={{flex: "0 0 60px", width: "60px", marginRight: "20px"}} />
          <div style={{flex: "1 1", justifyContent: "center", alignContent: "center"}}>
            Todas las notificaciones se enviarán a su correo: <br/>
            <span className='text-lg'>{persona.email.value}</span>
          </div>
        </div>
        
        {prestacion.postrequisite!=='' && <PopUp
                title='Importante'
                message={prestacion.postrequisite}
        />}

      </div>}
    </section>);
};

AltaReclamosPaso5.propTypes = {
  options: PropTypes.object.isRequired,
};

export default AltaReclamosPaso5;
