/* eslint-disable react/prop-types */
import React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-undef
const api = process.env.NODE_ENV=='development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

// value: {codigo: '1234', nombre: 'NECOCHEA'}
// eslint-disable-next-line no-unused-vars
const BuscadorCalles = ({value: {codigo, nombre}, onChange, error, label, ...others}) => {
  const [open, setOpen] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastSearch, setLastSearch] = useState(''); // Siempre en mayusculas
  const [searchText, setSearchText] = useState(nombre);

  /**
   * Buscar las calles que coinciden con las 3 primeras letras
   * @param {*} busqueda 
   */
  async function load(busqueda) { 
    try {
      if(busqueda=='') {
        setLastSearch('');
        setOptionsList([]);
        return;
      }
      // si la ultima busqueda fue "NEC" y la actual es "NECO" no la repito, ya que los resultados de NEC contienen los de NECO
      if(busqueda.length >= 3 && (lastSearch=='' || !busqueda.toUpperCase().includes(lastSearch))){
        setLoading(true);
        const url = new URL(`${api}/streetMGP`);  
        url.searchParams.set('search', busqueda);
        const response = await fetch(url);
        const data = await response.json();
        const callesData = data.calles.map((c)=> ({label: c.descripcion, id: c.codigo}));
        setOptionsList(callesData);
        setLastSearch(busqueda.toUpperCase());
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  // Vaciar la lista cuando se cierra el dropdown
  useEffect(() => {
    if (!open) {
      setOptionsList([]);
      setLastSearch('');
    }
  }, [open]);

  // Si cambia el nombre de la calle afuera del componente
  useEffect(() => {
    if (nombre!=searchText) {
      setSearchText(nombre);
      setOptionsList([]);
      setLastSearch('');
    }
  }, [nombre]);

  const handleInputChange = (event, newInputValue) => {
    // Si el texto matchea exacto una opcion, se elige la opcion directamente
    const key = newInputValue.trim().toUpperCase();
    const found = optionsList.find(o => o.label.toUpperCase()==key);
    if (found) {
      setSearchText(found?.label);
      onChange({nombre: found?.label || '', codigo: found?.id || ''});    
      setOptionsList([]);
      setLastSearch('');
      setOpen(false);
    } else {
      setSearchText(newInputValue);
      load(newInputValue);
    }
  }

  const handleChange = (event, newValue) => {
    onChange({nombre: newValue?.label || '', codigo: newValue?.id || ''});    
    setOptionsList([]);
    setLastSearch('');
    setOpen(false); // Borra la lista de opciones
  }

  const inputComponent = (params) => {
    return (
      <TextField
        {...params}
        value={searchText}
        required
        error={error}
        label={label}
        InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
          ),
        }}
      />);
  }
  return (
        <Autocomplete
          {...others}
          inputValue={searchText}
          onInputChange={handleInputChange}
          value={searchText}
          onChange={handleChange}
          options={optionsList}
          isOptionEqualToValue={(opt, v) => (opt.codigo==v.codigo)}
          noOptionsText={'Nombre de la calle'}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={()=> setOpen(false)}
          loading={loading}
          filterOptions={(x) => x}
          renderInput={inputComponent}
        />
    );
}

BuscadorCalles.propTypes = {
  value: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

export default BuscadorCalles;
