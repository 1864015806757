import { create } from 'zustand'
import VialidadImage from '../img/v2/vialidad.jpg';
import ServiciosUrbanosImage from '../img/v2/servicios-urbanos.jpg';
import PirotecniaImage from '../img/v2/pirotecnia.jpg';
import TransporteImage from '../img/v2/transporte.jpg';
import SeguridadImage from '../img/v2/seguridad.jpg';
import SemaforosImage from '../img/v2/semaforos.jpg';
import DesarrolloSocialImage from '../img/v2/desarrollo-social.jpg';
import BromatologiaImage from '../img/v2/bromatologia.jpg';
import PatrimonialImage from '../img/v2/patrimonio.jpg';
import ArboladoImage from '../img/v2/arbolado.jpg';
import {v4 as uuidv4} from 'uuid';

const categorias = [
    {code: '01', name: 'Vialidad y alumbrado', image: VialidadImage}, 
    {code: '03,30,31', name: 'Servicios urbanos', image: ServiciosUrbanosImage}, 
    {code: '21', name: 'Arbolado', image: ArboladoImage}, 
    {code: '23', name: 'Pirotecnia', image: PirotecniaImage}, 
    {code: '08,32', name: 'Transporte', image: TransporteImage}, 
    {code: '07', name: 'Seguridad', image: SeguridadImage}, 
    {code: '04', name: 'Semaforos', image: SemaforosImage}, 
    {code: '29', name: 'Desarrollo social', image: DesarrolloSocialImage}, 
    {code: '09', name: 'Bromatologia', image: BromatologiaImage},
    {code: '16', name: 'Restitución Patrimonial', image: PatrimonialImage},
];

const ticket = sessionStorage.getItem('ticket');
const ticketObj = ticket ? JSON.parse(ticket) : null;

 /**
  * Centrar el mapa en el centro de la ciudad y ajustar el zoom
  */
 const resetCoordsModel = () => ({
    // eslint-disable-next-line no-undef
    latitud: (process.env?.REACT_APP_LAT || -38.0175459) * 1,
    // eslint-disable-next-line no-undef
    longitud: (process.env?.REACT_APP_LNG || -57.6705731) * 1,
    barrio: '',
    zonaurbana: '',
    error: { calle: false, altura: false, calleCorta: false },
    zoom: 15,
    elemento: '', elementoDescription: '',
    playa: '',
    plaza: '',
    lugares: [],
    linea: '', interno: '', fecha_hora: '',
    villa: '', manzana: '', casa: '',
    cementerio: '', calleCementerio: '', sepultura: '', sector: '', numero: '', fila: '',
    organismo: '',
    validada: false,
});

/**
 * eliminar seleccion de calle y altura y elemento seleccionado
 */
const resetDireccionModel = () => ({
    modo: 'CALLE-ALTURA',
    calle: { codigo: '', nombre: '' },
    calleCorta: { codigo: '', nombre: '' },
    altura: '',
    piso: '',
    dpto: '',
    barrio: '',
    comuna: '',
    nombreFantasia: '',
});

const ticketModel = () => ({
    nonce: '',
    sent: false,
    description: '',
    utterance: '',
    answers: [],
    ticket: null,
    reCAPTCHA: false,
    error: null,
    questionnaire: [],
    elementos: [],
    categorias: categorias,
    prestacion: {
        error: false,
        code: '',
        geolocation: 'DOMICILIO',
        keywords: '',
        metadata: '',
        name: '',
        resolutionTime: '',
        type: 'RECLAMO',
        attachments: [],
        categoria: -1,
        prerequisite: '',
        postrequisite: '',
    },
    direccion: {
        ...resetDireccionModel(),
        ...resetCoordsModel(),
    }
});

const actualTicket = ticketObj ? ticketObj : ticketModel();

const useTicketStore = create((set) => (
    {
        ...actualTicket,

        setSent: (newValue) => {
            set((state) => { 
                const newState = { ...state, sent: newValue } 
                sessionStorage.setItem('ticket', JSON.stringify(newState));
                return newState;
            });
        },

        setPrestacion: (newValue) => {
            set(state => {
                // buscar la categoria de la prestacion, si el codigo de la prestacion empieza por el codigo de una categoria
                const prestacion = {...state.prestacion, ...newValue, categoria: -1};
                for(let i = 0; i < categorias.length; i++) {
                    const codes = categorias[i].code.split(',');
                    for(let j = 0; j < codes.length; j++) {
                        if(prestacion.code.startsWith(codes[j])) {
                            prestacion.categoria = i;
                            break;
                        }                        
                    }
                    if (prestacion.categoria != -1) break;
                }
                const newState = { 
                    ...state, 
                    prestacion, 
                    questionnaire: [], // al setear una nueva prestacion, borrar el listado de preguntas 
                    elementos: [],  // al setear una nueva prestacion, borrar el listado de elementos
                };
                sessionStorage.setItem('ticket', JSON.stringify(newState));
                return newState;
             });
        },

        setCategoria: (newValue) => set(state => {
            const newState = { ...state, prestacion: {...state.prestacion, categoria: newValue}};
            sessionStorage.setItem('ticket', JSON.stringify(newState));
            return newState;
        }),
        
        setUtterance: (newValue) => set(state => {
            const newState = { ...state, utterance: newValue}; 
            sessionStorage.setItem('ticket', JSON.stringify(newState));
            return newState;
        }),
        
        setElementos: (newValue) => set(state => {
            const newState = { ...state, elementos: [...state.elementos, ...newValue]}; 
            sessionStorage.setItem('ticket', JSON.stringify(newState)); 
            return newState; 
        }),
        
        setDescription: (newValue) => set(state => {
            const newState = { ...state, description: newValue };
            sessionStorage.setItem('ticket', JSON.stringify(newState));
            return newState;
        }),
        
        setAnswers: (newValue) => set(state => {
            // El array de answers tiene objetos {code, answer}
         const newState = { ...state, answers: [...newValue]}; 
            sessionStorage.setItem('ticket', JSON.stringify(newState)); 
            return newState; 
        }),
        
        setDireccion: (newValue) => set(state => {
            const newState = { ...state, direccion: {...state.direccion, ...newValue} }; 
            sessionStorage.setItem('ticket', JSON.stringify(newState)); 
            return newState; 
        }), 

        setTicket: (newValue) => set(state => {
            const newState = { ...state, ticket: newValue, sent: true }; 
            sessionStorage.setItem('ticket', JSON.stringify(newState)); 
            return newState; 
        }), 
        
        setReCAPTCHA: (newValue) => set(state => {
            const newState = { ...state, reCAPTCHA: newValue };
            sessionStorage.setItem('ticket', JSON.stringify(newState));
            return newState;
         }),

        reset: () => set((state) => {
                const nonce = uuidv4();
                const newState = { ...ticketModel(), nonce, utterance: state.utterance};
                sessionStorage.setItem('ticket', JSON.stringify(newState));
                return newState;
        }),

        resetCoords: () => set(state => {
            const newState = { ...state, direccion: {...state.direccion, ...resetCoordsModel()} };
            sessionStorage.setItem('ticket', JSON.stringify(newState));
            return newState;
        }),
        
        resetDireccion: () => set(state => {
            const newState = { 
                ...state, 
                direccion: {
                    ...state.direccion, 
                    ...resetDireccionModel(), 
                    ...resetCoordsModel(), 
                    validada: false,
                    questionnaire: [], 
                    elementos: []
                } 
            };
            sessionStorage.setItem('ticket', JSON.stringify(newState));
            return newState;
        }),

        setQuestionnaire: (newValue) => set(state => {
            const newState = { ...state, questionnaire: newValue || []};
            sessionStorage.setItem('ticket', JSON.stringify(newState));
            return newState;
        }),

        setAttachments: (newValue) => set(state => {
            const newState = { ...state, prestacion: {...state.prestacion, attachments: newValue} }; 
            sessionStorage.setItem('ticket', JSON.stringify(newState)); 
            return newState; 
        }),
    }))

export default useTicketStore;
